<template>
  <div v-if="dataReady">
    <b-container>
      <b-row>
        <b-col>
          <Heading type="h1" :title="trans('global.chooser')" />
        </b-col>
      </b-row>
    </b-container>
    <div class="levelselector">
      <b-container>
        <b-row>
          <b-col>
            <b-tabs v-if="mapData.__layers" v-model="currentMapLayerId" content-class="mt-3" class="zoomlevel-tabs">
              <b-tab
                v-for="layer in mapData.__layers"
                :key="layer.id"
                :title="trans('prospects.maptool.zoomlevel',
                              { layer: layer.id }
                )"
                @click="tabClick(layer.id)"
              >
              </b-tab>
            </b-tabs>
            <b-tabs v-else v-model="currentMapLayerId" content-class="mt-3" class="zoomlevel-tabs">
              <b-tab
                :title="trans('prospects.maptool.no_map_levels_found')"
                disabled
              >
              </b-tab>
            </b-tabs>
            <b-btn
              variant="primary"
              class="add-map-layer"
              @click="addMapLevel"
            >
              <i class="far fa-plus"></i> {{ trans('prospects.maptool.add_map_layer') }}
            </b-btn>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container v-if="mapData.__layers[currentMapLayerId] !== undefined" class="leafletmap">
      <b-row>
        <b-col id="mapcontainer">
          <div id="map" class="leaflet-container"></div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div v-if="uploadStatus === 1" class="room-image-progress">
            <b-progress
              :value="Math.round(progress)"
              variant="primary"
              striped
              :animated="true"
              show-progress
            ></b-progress>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form>
            <b-form-file
              :id="'map_zoom_level_' + mapData.zoom"
              v-model="formData.image"
              :placeholder="trans('projects.tools.map.image_placeholder')"
              :browse-text="trans('projects.tools.map.button_text')"
              class="mt-2"
              accept="image/jpeg, image/png"
              @change="uploadImage"
            >
            </b-form-file>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-else>
      <b-row>
        <b-col>
          {{ trans('prospects.maptool.no_levels_added') }}
        </b-col>
      </b-row>
    </b-container>
  </div>
  <div v-else>
    <p>Laster inn kartdata...</p>
  </div>
</template>

<script>
  import L from 'leaflet'
  import 'leaflet-draw'
  import 'leaflet-snap'
  import Vue from 'vue'
  import { mapGetters } from 'vuex'

  const Heading = () => import('../../../Core/Heading')

  const fb = require('../../../../firebaseConfig')
  // import deepMerge from 'deepmerge'
  // import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
  // import { LMap, LTileLayer } from 'vue2-leaflet'

  export default {
    name: 'ProjectSalesChooser',
    components: {
      Heading
      // LMap,
      // LTileLayer,
      // LMarker
    },
    props: {
      project: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        cdnUrl: process.env.VUE_APP_IMAGE_CDN_URL,
        uploadStatus: 0, // 0 = idle, 1 = progress, 2 = complete
        progress: 0,
        map: null, // instance
        tabControl: 0,
        currentMapLayerId: 0,
        mapData: {}, // all layers
        formData: {
          image: []
        },
        mapDataDefault: {
          zoom: 0,
          maxZoom: 0,
          center: [0, 0],
          scrollWheelZoom: false,
          zoomControl: false
        }
      }
    },
    computed: {
      ...mapGetters({
        user: 'auth/get'
      }),
      dataReady () {
        return this.mapData.__layers !== undefined // Layers have been queried in the DB
      },
      /**
       * mapTiles ()
       * Shows the tiles for the current zoom level
       */
      mapTiles () {
        console.log('MapLevels regenerates....')
        if (
          this.mapData.__layers !== false &&
          this.mapData.__layers[this.currentMapLayerId] !== undefined &&
          this.mapData.__layers[this.currentMapLayerId].tiles !== undefined
        ) {
          return {
            imageURL: this.cdnUrl + '/' + this.mapData.__layers[this.currentMapLayerId].tiles.path + '/{x}{y}.' + this.mapData.__layers[this.currentMapLayerId].tiles.ext
          }
        } else {
          // Create a default setup
          return {
            _exists: false,
            imageURL: 'https://via.placeholder.com/256'
          }
        }
      }
    },
    mounted () {
      this.getMapData().then(() => {
        if (this.mapData.__layers !== false && Object.keys(this.mapData.__layers).length > 0) {
          this.mapInit()
        }
      })
    },
    methods: {
      tabClick (layerId) {
        this.currentMapLayerId = layerId
        if (this.mapData.__layers !== false && this.mapData.__layers[this.currentMapLayerId] !== undefined) {
          this.mapInit()
        }
      },
      addMapLevel () {
        let initMap = false
        const self = this
        const created = new Date()
        const mapLayersCount = this.mapData.__layers !== undefined &&
          this.mapData.__layers !== false
          ? Object.keys(this.mapData.__layers).length
          : 0

        const batch = fb.db.batch()

        const mapDocRef = fb.projectsCollection.doc(this.project._id)

        // Save the layer-data
        const layerData = {
          id: mapLayersCount,
          created,
          modified: created,
          zoom: mapLayersCount
        }
        batch.set(
          mapDocRef
            .collection('tools')
            .doc('map')
            .collection('layers')
            .doc(mapLayersCount.toString()),
          layerData
        )

        const mapDocData = {
          maxZoom: mapLayersCount,
          modified: created
        }

        if (Object.keys(this.mapData.__layers).length === 0) {
          initMap = true
          // Save initial settings when first layer is created
          mapDocData.created = created
          mapDocData.scrollWheelZoom = false
          mapDocData.zoomControl = true
          mapDocData.__layers = {
            0: layerData
          }

          batch.set(
            mapDocRef
              .collection('tools')
              .doc('map'),
            mapDocData
          )
        } else {
          mapDocData[`__layers.${mapLayersCount}`] = layerData
          batch.update(
            mapDocRef.collection('tools')
              .doc('map'),
            mapDocData
          )
        }

        batch.commit().then(() => {
          self.$bvToast.toast(self.trans('prospects.maptool.added_level_successfully'), {
            title: self.trans('global.success'),
            variant: 'success',
            solid: true
          })

          self.getMapData().then(() => {
            console.log(self.mapData)

            if (
              initMap &&
              self.mapData.__layers !== false &&
              self.mapData.__layers[0] !== undefined
            ) {
              // TODO: Replace this timeout with a more robust check
              setTimeout(() => {
                self.mapInit()
              }, 400)
            }
          })
        }).catch((err) => {
          self.$bvToast.toast(self.trans('prospects.maptool.added_level_error'), {
            title: self.trans('global.error'),
            variant: 'warning',
            solid: true
          })
          console.log(err)
        })
      },
      /**
       * getMapData ()
       * Returns data from the tool-doc (global data for all layers), and data from the layers
       */
      getMapData () {
        const self = this

        if (
          this.project !== undefined
          // this.project.tools !== undefined &&
          // this.project.tools.map !== undefined
        ) {
          return fb.projectsCollection
            .doc(this.project._id)
            .collection('tools')
            .doc('map')
            .get()
            .then((mapDoc) => {
              if (mapDoc.exists) {
                console.log('The data exists:')
                console.log(mapDoc.data())
                self.mapData = mapDoc.data()
              } else {
                console.log('The mapdata does not exist')
                Vue.set(self.mapData, '__layers', false)
              }
            }).catch((err) => {
              console.log('There was a error while getting the tool (map), quitting:')
              console.log(err)
            })
        } else {
          console.log('Nope')
          return null
        }
      },
      mapInit () {
        const self = this

        console.log('CurrentLayer:')
        console.log(this.currentMapLayerId)

        const layerData = this.mapData.__layers !== undefined &&
          this.mapData.__layers[this.currentMapLayerId] !== undefined
          ? this.mapData.__layers[this.currentMapLayerId]
          : null

        const options = {
          crs: L.CRS.Simple, // coordinate reference system
          zoom: 0, // layerData !== null && layerData.zoom !== undefined ? layerData.zoom : this.mapDataDefault.zoom,
          maxZoom: 0, // layerData !== null && layerData.zoom !== undefined ? layerData.zoom : this.mapDataDefault.zoom,
          // maxZoom: this.mapData.maxZoom !== undefined ? this.mapData.maxZoom : this.mapDataDefault.maxZoom,
          center: layerData !== null && layerData.center !== undefined ? layerData.center : this.mapDataDefault.center,
          scrollWheelZoom: false,
          zoomControl: false
        }

        // maxBounds
        if (layerData !== null && layerData.maxBounds !== undefined) {
          options.maxBounds = [
            [layerData.maxBounds.start[0], layerData.maxBounds.start[1]],
            [layerData.maxBounds.end[0], layerData.maxBounds.end[1]]
          ]
        }

        console.log('Options:')
        console.log(options)

        if (self.map !== null) self.map.remove()

        self.map = L.map('map', options)

        // This layers tiles
        L.tileLayer(self.mapTiles.imageURL, {
          maxZoom: options.maxZoom,
          minZoom: options.maxZoom
        }).addTo(self.map)

        if (options.maxBounds !== undefined) {
          self.map.fitBounds(options.maxBounds)
          self.map.on('drag', function () {
            self.map.panInsideBounds(options.maxBounds, { animate: false })
          })
        }

        const mapObjects = new L.FeatureGroup()
        self.map.addLayer(mapObjects)

        const geoJSONData = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: { },
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [756.1875, 539.5, 0],
                    [834.1875, 539.5, 0],
                    [834.1875, 694.5, 0],
                    [756.1875, 694.5, 0]
                  ]
                ]
              }
            }
          ]
        }

        mapObjects.addLayer(L.polygon(geoJSONData.features[0].geometry.coordinates[0]))

        // mapObjects.snapediting = new L.Handler.PolylineSnap(self.map, mapObjects)
        // mapObjects.snapediting.enable()

        mapObjects.on('click', (e) => {
          console.log('Clicked!')
          console.log(e)
        })

        // Leaflet Draw methods:

        self.map.addControl(new L.Control.Draw({
          draw: {
            polygon: {
              allowIntersection: false,
              showArea: true
            },
            circlemarker: false
          },
          edit: {
            featureGroup: mapObjects
          }
        }))

        self.map.on(L.Draw.Event.CREATED, function (e) {
          // const type = e.layerType
          const layer = e.layer
          const db = layer.toGeoJSON()

          console.log(db)

          mapObjects.addLayer(layer)
        })
      },

      /**
       * uploadImage ()
       * Uplaods the map background image
       * @param e
       */
      async uploadImage (e) {
        const self = this
        const layerId = this.currentMapLayerId.toString()
        const imageData = e.target.files[0]
        let imageId = null

        console.log('imagedata:')
        console.log(imageData)

        const imageDims = await this.getImageDimentions(imageData)

        console.log(imageDims)

        if (imageDims.width % 256 === 0 && imageDims.height % 256 === 0) {
          if (imageData !== undefined && typeof imageData === 'object' && imageData.name !== undefined) {
            self.uploadStatus = 1

            let randomTxt = ''
            const length = 16
            const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

            for (let i = 0; i < length; i++) {
              randomTxt += possible.charAt(Math.floor(Math.random() * possible.length))
            }

            const storageRef = fb.storage.ref()
            const metadata = {
              contentType: imageData.type
            }

            const newFilename = randomTxt + '.' + imageData.name.split('.').pop()
            const orgFilename = imageData.name
            const imageTargetPath =
              'projects/' +
              this.project.varycode +
              '/tools/map/zoom/' +
              layerId +
              '/images'

            // Upload the file
            const uploadTask = storageRef.child(imageTargetPath + '/' + newFilename).put(imageData, metadata)

            return uploadTask.on('state_changed', function (snapshot) {
              self.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

              switch (snapshot.state) {
                case 'paused':
                  console.log('Upload is paused')
                  break
                case 'running':
                  console.log('Upload is running')
              }
            }, function (error) {
              switch (error.code) {
                case 'storage/unauthorized':
                  console.log('Storage autorization required, access denied...')
                  break
                case 'storage/canceled':
                  console.log('The upload was cancelled')
                  break
                case 'storage/unknown':
                  console.log('An unknown error occurred during upload')
                  break
              }
            }, function () {
              self.uploadStatus = 2

              const saveData = {
                createdOn: new Date(),
                file: newFilename,
                orgFile: orgFilename,
                path: imageTargetPath,
                meta: metadata,
                userId: self.user.uid,
                deleted: false
              }

              return fb.projectsCollection
                .doc(self.project._id)
                .collection('tools')
                .doc('map')
                .collection('layers')
                .doc(layerId)
                .collection('images')
                .add(saveData)
                .then((res) => {
                  self.$bvToast.toast(self.trans('prospects.designer.image_uploaded_successfully'), {
                    title: self.trans('global.success'),
                    variant: 'success',
                    solid: true
                  })

                  // const batch = fb.db.batch()
                  // const toolDocRef = fb.projectsCollection
                  //   .doc(self.project._id)
                  //   .collection('tools')
                  //   .doc('map')
                  //
                  // batch.add(
                  //   toolDocRef
                  //     .collection('layers')
                  //     .doc('layer_' + layerId)
                  //     .collection('image')
                  //     .add(saveData)
                  // )
                  //
                  // // Update layer-doc with active image ID
                  // fb.projectsCollection
                  //   .doc(self.project._id)
                  //   .collection('tools')
                  //   .doc('map')
                  //   .collection('layers')
                  //   .doc('layer_' + layerId)
                  //   .update({
                  //     imageId: res.id
                  //   })

                  // // Update
                  // fb.projectsCollection
                  //   .doc(self.project._id)
                  //   .collection('sales')
                  //   .doc('tools')
                  //   .collection('map')
                  //   .doc('settings')
                  //   .update({
                  //     center: [imageDims.height / 2, imageDims.width / 2],
                  //     maxBounds: {
                  //       start: [0, 0], // x y start
                  //       end: [imageDims.height, imageDims.width]
                  //     }
                  //   })

                  return res
                })
                .then((res) => {
                  console.log('Triggering the cloud function here:')
                  const createTiles = fb.func.httpsCallable('createTiles')

                  saveData._id = imageId = res.id

                  const xTiles = imageDims.width / 256
                  const yTiles = imageDims.height / 256

                  return createTiles(
                    {
                      project: self.project,
                      layer: layerId,
                      image: saveData,
                      z: layerId,
                      x: xTiles,
                      y: yTiles
                    })
                    .then((res) => {
                      console.log('CREATED THE TILES:')
                      console.log(res)
                      return res
                    }).catch((err) => {
                      console.log('DID NOT CREATE THE TILES:')
                      console.log(err)
                      return err
                    })
                }).then((res) => {
                  console.log('FINISHED :)')
                  console.log(res)

                  console.log(res.data.tiles.length)

                  if (
                    res !== undefined &&
                    res !== null &&
                    res.data !== undefined &&
                    res.data !== null &&
                    res.data.tiles !== undefined &&
                    res.data.tiles.length > 0 &&
                    res.data.path !== undefined
                  ) {
                    const tilesData = {
                      createdOn: new Date(),
                      imageId,
                      tiles: res.data.tiles,
                      path: res.data.path,
                      ext: res.data.ext,
                      userId: self.user.uid,
                      deleted: false
                    }

                    return fb.projectsCollection
                      .doc(self.project._id)
                      .collection('tools')
                      .doc('map')
                      .collection('layers')
                      .doc(layerId)
                      .collection('tiles')
                      .add(tilesData)
                      .then((res) => {
                        console.log(res)
                        self.$bvToast.toast(self.trans('prospects.maptool.tiles_uploaded_successfully'), {
                          title: self.trans('global.success'),
                          variant: 'success',
                          solid: true
                        })

                        const batch = fb.db.batch()
                        const mapDocRef = fb.projectsCollection
                          .doc(self.project._id)
                          .collection('tools')
                          .doc('map')

                        // Update the layerDoc
                        batch.update(
                          mapDocRef
                            .collection('layers')
                            .doc(layerId),
                          {
                            tileSetId: res.id,
                            center: [imageDims.height / 2, imageDims.width / 2],
                            maxBounds: {
                              start: [0, 0], // x y start
                              end: [imageDims.height, imageDims.width]
                            }
                          }
                        )

                        // Update the mapDoc
                        batch.update(
                          mapDocRef,
                          {
                            [`__layers.${layerId}.center`]: [imageDims.height / 2, imageDims.width / 2],
                            [`__layers.${layerId}.maxBounds`]: {
                              start: [0, 0], // x y start
                              end: [imageDims.height, imageDims.width]
                            },
                            [`__layers.${layerId}.tiles`]: {
                              id: res.id,
                              ext: tilesData.ext,
                              path: tilesData.path
                            }
                          }
                        )

                        batch.commit()

                        self.getMapData().then(() => {
                          self.mapInit()
                        })
                      }).catch((err) => {
                        console.log('Error while updating the projects -> sales -> tools -> map -> level_' +
                          layerId +
                          ' -> tiles document...'
                        )
                        console.log(err)
                      })
                  }
                })
                .catch((err) => {
                  // Do errorhandling here
                  console.log('Error while updating map image:')
                  console.log(err)
                })
            })
          }
        } else {
          self.$bvToast.toast(self.trans('prospects.maptool.image_not_divisible'), {
            title: self.trans('global.error'),
            variant: 'warning',
            solid: true
          })
          this.formData.image = []
        }
      },
      getImageDimentions (img) {
        return new Promise((resolve, reject) => {
          const fr = new FileReader()
          fr.readAsDataURL(img)

          fr.onload = () => {
            const img = new Image()
            img.src = fr.result

            img.onload = () => {
              console.log('ImageSizes are ready')
              console.log(img.width)
              console.log(img.height)

              const imgdata = {
                width: img.width,
                height: img.height
              }

              resolve(imgdata)
            }
          }
        })
      }
    }
  }
</script>
